import * as React from 'react';
import { Router } from '@reach/router';
import ViewNFT from '../components/ViewNFT';
import G3TokenGateProvider from '../components/Dashboard/contexts/G3TokenGateContext';

export default () => {
    return (
        <G3TokenGateProvider>
            <Router basepath="/token">
                <ViewNFT
                    /*@ts-ignore*/
                    path="/:chain/:tokenAddress/:tokenId"
                    viewType="page"
                    styles={{
                        marginTop: ['0rem', '5rem'],
                    }}
                />
            </Router>
        </G3TokenGateProvider>
    );
};
